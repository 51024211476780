import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { TextResources } from "@portal/assets/text/TextResources";
import useBankFundList from "@portal/hooks/useBankFundList";
import { useAppSelector } from "@portal/redux/store";
import { FC, useRef, useState } from "react";
import { useDebounce } from "rooks";
import { ISearchComboboxProps, ISearchHit } from "./types";
import useBank from "@portal/hooks/useBank";
import { IInfrontStockItem } from "@portal/redux/reducers/api/infrontAllStocks/types";
import { IFund } from "@portal/types/api/IFund";
import StockSearchItem from "./stockSearchItem";
import FundSearchItem from "./fundSearchItem";

const SearchCombobox: FC<ISearchComboboxProps> = ({
  searchBoxRef,
  handleClickSearchResult,
  searchValue,
  searchResult,
  setSearchResult,
  positionAbsolute = true,
  fixedWidthClass,
  fundOnly = false,
  stockOnly = false,
  resultPositionLeft = false,
}) => {
  const { data: bankData } = useBank();
  const { allFunds } = useBankFundList();
  const { allStocks } = useAppSelector(state => state.other.api.infrontAllStocks);
  const searchListRef = useRef<HTMLUListElement>(null);
  const [searching, setSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const handleSearch = (val: string) => {
    setSearching(true);
    if (val.length < 3) {
      setSearchResult([]);
      return;
    }
    let searchResult = stockOnly
      ? []
      : (allFunds
          ?.filter(
            fund =>
              fund.name.toLowerCase().includes(val.toLowerCase()) ||
              fund.securityTypeName.toLowerCase().includes(val.toLowerCase()),
          )
          .map(f => {
            return { item: { ...f, id: f.isin }, type: "fund" };
          }) as ISearchHit[]);

    if (bankData?.stockTradingEnabled && !fundOnly) {
      searchResult = (
        allStocks
          ?.filter(
            stock =>
              stock.name.toLowerCase().includes(val.toLowerCase()) || stock.ticker.toLowerCase().includes(val.toLowerCase()),
          )
          .map(s => {
            return { item: { ...s, id: s.ticker }, type: "stock" };
          }) as ISearchHit[]
      ).concat(searchResult);
    }

    if (searchResult) {
      setSearchResult(searchResult);
    } else {
      setSearchResult([]);
    }
    setSearching(false);
  };
  const handleSearchDebounced = useDebounce(handleSearch, 300);

  return (
    <div className={`${positionAbsolute ? "absolute right-0 lg:-top-3 z-10" : ""}`}>
      <Combobox
        value={searchValue}
        onChange={(hit: ISearchHit | "") => {
          handleClickSearchResult(hit);
        }}
        immediate={searchResult.length > 0}
        onClose={() => {
          setQuery("");
          setSearchResult([]);
        }}
      >
        {({ open }) => (
          <>
            <ComboboxInput
              ref={searchBoxRef}
              className={`${
                fixedWidthClass ? `${fixedWidthClass}` : open ? "w-48" : "w-16"
              } peer-focus:bg-green rounded-md px-2 h-7 border border-greyBorder bg-[url('/img/magnifying-glass.svg')] bg-[right_2px_center] bg-no-repeat bg-[length:18px_18px] focus:outline-0 ${
                fixedWidthClass ? "" : "focus:w-48 duration-500"
              }`}
              onChange={e => {
                setQuery(e.target.value);
                handleSearchDebounced(e.target.value);
              }}
            />

            <ComboboxOptions
              ref={searchListRef}
              className={`${
                query.length < 3 ? "hidden" : ""
              } peer bg-white text-blueDark rounded-md p-1 border drop-shadow-lg border-greyBorder w-80 sm:w-96 absolute z-30 max-h-96 top-8 ${
                resultPositionLeft ? "left-0" : "right-0"
              } overflow-y-auto overflow-x-hidden`}
            >
              {searchResult.length > 0 &&
                searchResult.map(hit => (
                  <ComboboxOption
                    key={hit.item.id}
                    value={hit}
                    className="cursor-pointer hover:bg-blueHover data-[focus]:bg-blueHover rounded-md p-1"
                  >
                    {hit.type === "stock" ? (
                      <StockSearchItem item={hit.item as IInfrontStockItem} />
                    ) : (
                      <FundSearchItem item={hit.item as IFund} />
                    )}
                  </ComboboxOption>
                ))}
              {searchResult.length === 0 && !searching && (
                <ComboboxOption value="">{TextResources.SEARCH_NO_RESULTS}</ComboboxOption>
              )}
            </ComboboxOptions>
          </>
        )}
      </Combobox>
    </div>
  );
};

export default SearchCombobox;
