import dynamic from "next/dynamic";

interface Props {
  centerId: string;
}

const BankLogo = dynamic(() => import("../logos"), {
  loading: () => <div></div>,
});

const BankLogoComponent = ({ centerId }: Props) => (
  <div className="flex mr-4 max-h-[60px] min-w-[140px] max-w-[210px]">
    <BankLogo centerId={centerId} />
  </div>
);

export default BankLogoComponent;
