import { useRef, useState } from "react";
import { useRouter } from "nextjs-toploader/app";
import { IInfrontStockItem } from "@portal/redux/reducers/api/infrontAllStocks/types";
import SearchCombobox from "@portal/components/molecules/searchCombobox";
import { ISearchHit } from "@portal/components/molecules/searchCombobox/types";

const Search = () => {
  const router = useRouter();
  const searchRef = useRef<HTMLInputElement>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);

  const [searchValue, setSearchValue] = useState<ISearchHit | "">("");
  const [searchResult, setSearchResult] = useState<ISearchHit[]>([]);

  const handleClickSearchResult = (hit: ISearchHit | "" | undefined) => {
    if (!hit) return;
    setSearchValue("");
    setSearchResult([]);

    if (hit.type === "stock") {
      router.push(`/trader/stocks/${hit.item.id}?feedId=${(hit.item as IInfrontStockItem).feed}`);
    } else {
      router.push(`/funds?isin=${hit.item.id}`);
    }
  };

  return (
    <div className="absolute top-[60px] right-0 lg:flex lg:static text-blueDark ">
      <div className="relative">
        <div className="flex" ref={searchWrapperRef}>
          <SearchCombobox
            searchBoxRef={searchRef}
            handleClickSearchResult={handleClickSearchResult}
            searchValue={searchValue}
            searchResult={searchResult}
            setSearchResult={setSearchResult}
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
